import { upArray } from './up-array';
import { toIs } from './to-is';
import { get, isEmpty, isNil, omit, omitBy } from 'lodash-es';
import { canStringFormat, isFalsy, isPowerFalsy, stringFormat } from '@/v2/utils';

type MapperValueFunc = 'split' | 'join' | 'toString' | 'toNumber' | 'upArrary' | 'tryNumber' | 'dayjs';
type MapperValueSource = `${string}${MapperValueFunc}`;
type MapperRuleExpression = string;
type MapperValue = MapperRuleExpression | MapperValueSource | Mapper | Func;
export interface Mapper {
  [x: string]: MapperValue | MapperValue[];
}

export const mappingFuncMap = {
  split(value: string, sep = ',') {
    if (typeof value !== 'string') return value;
    return value.split(sep);
  },
  join(value: any[], sep = ',') {
    if (!Array.isArray(value)) return value;
    return value.join(sep);
  },
  toIs: (value: string) => {
    return toIs(value);
  },
  add: (value) => {
    console.log('ooo--->>>', value);
    return value + 1;
  },
  toString: (value: any) => {
    try {
      return String(value);
    } catch (e) {
      return value;
    }
  }
};

/**
 * 根据 mapperValue 获取映射值
 */
export function getRuleValue(obj: Row, rule: any, source: any) {
  if (typeof rule === 'string') {
    // 判断当前rule为表达式形式
    if (rule.split(/\s{1,}/g).length > 1) {
      return getMapValue(obj, rule, source);
    }

    // 返回文本值
    if (/^\^/.test(rule)) {
      return rule.replace(/^\^/, '');
    }

    const [key, functionName, params] = rule.split('::');

    if (!functionName || !mappingFuncMap[functionName]) {
      if (canStringFormat(key)) {
        return stringFormat(key, obj);
      }
      return get(obj, key);
    }

    return mappingFuncMap?.[functionName](get(obj, key), params);
  }

  if (typeof rule === 'function') {
    // todo 源是对象时，应该返回的是key
    return rule(obj, source?.inx, source?.obj);
  }

  if (Array.isArray(rule)) {
    return rule.map((mapperValue) => getRuleValue(obj, mapperValue, source));
  }

  if (typeof rule === 'object') {
    return mapping(obj, rule, source?.type);
  }

  return getRuleValue(obj, String(rule), source);
}

/**
 * 根据 mapper 规则表达式获取值
 * expression: `a || b`, `a && b`, `a ?? b`, `a && b ?? c || d`
 */
export function getMapValue(obj: Row, mapperRulesExpression: string, source?: any) {
  const rules = mapperRulesExpression.split(/\s{1,}/g) ?? [];
  let value;
  // 支持管道 ‘|’
  let isPie = false;
  for (let rule of rules) {
    // 可选链模式
    if (rule === '??' && !isNil(value)) break;
    // 或
    if (rule === '||' && !!value) break;
    // 与
    if (rule === '&&' && !value) break;
    // 虚值
    if (rule === '!!' && !isFalsy(value)) break;
    // 虚值，isZero 模式
    if (rule === '!!0' && !isFalsy(value, true)) break;
    // 强虚值
    if (rule === '!!!' && !isPowerFalsy(value)) break;
    // 强虚值，isZero 模式
    if (rule === '!!!0' && !isPowerFalsy(value, true)) break;
    // 管道方法执行
    if (rule === '|') {
      // 标识管道模式
      isPie = true;
      continue;
    }

    // 处理管道标识函数
    if (isPie) {
      // @todo 支持参数
      // 管道先运行管道支持的函数
      // 若函数存在，则直接返回，否则返回字符串格式化后的值
      // 若管道前的值为nil，则不支持format的值
      value = (mappingFuncMap[rule]?.(value) ?? isNil(value)) ? value : stringFormat(rule, typeof value === 'object' ? value : [value]);
      isPie = false;
      continue;
    }

    // 处理其他情况 (普通文本返回, string format)
    //
    value = getRuleValue(obj, rule, source);
  }
  return value;
}

/**
 * @todo 将 type 改成 options 模式，
 * 可以接受自定的管道线，支持自定义方法
 * :rule -> 方法
 * ^rule -> 字符串
 * _rule -> number
 * {rule} -> string format 的参数替换值
 * rule -> 普通规则，源对象的 PropsPath
 */
export function mapping(obj: RRows | null | undefined, mapper?: Mapper, type: MapperType = 'cover') {
  if (isNil(obj)) return obj;
  if (isEmpty(mapper)) return obj;
  const rows = upArray(obj);
  const result = rows.map((item, inx) => {
    const result = Object.entries(mapper).reduce((temp, [target, source]) => {
      temp[target] = getRuleValue(item, source, { obj, mapper, type, target, inx });
      return temp;
    }, {});

    if (type === 'cover') return { ...item, ...result };
    if (type === 'pick') return result;
    if (type === 'touch') return { ...result, ...item };
  });
  return Array.isArray(obj) ? result : result[0];
}

export function picking(obj: RRows, mapper: Mapper | string[]) {
  const mapper$ = Array.isArray(mapper)
    ? mapper.reduce((temp, item) => {
        temp[item] = item;
        return temp;
      }, {})
    : mapper;
  return mapping(obj, mapper$, 'pick');
}

export function omiting(obj: Row | Row[], omitKeys: Row | ((value, key) => boolean) | string | string[]) {
  const rows = upArray(obj);
  const result = rows.map((item) => {
    if (Array.isArray(omitKeys) || typeof omitKeys === 'string') {
      return omit(item, upArray(omitKeys));
    }

    const func = typeof omitKeys === 'function' ? omitKeys : (value, key) => omitKeys[key] === value;
    return omitBy(item, func);
  });

  return Array.isArray(obj) ? (result as Rows) : (result[0] as Row);
}

export const getValue = (obj: Row | Row[], rule: string) => {
  const can = canStringFormat(rule);
  return can ? stringFormat(rule, obj)?.trim?.() : get(obj, rule);
};
