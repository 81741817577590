import { DingtalkUserDto } from './services/WebApi';
export enum URLHeaders {
  NoProcess = 'X-No-Process',
  NoError = 'X-No-Error'
}

export const HeaderNoProcess = { [URLHeaders.NoProcess]: true };

// 表单基本宽度
export const BaseTableWidth = 1610;
// 选择列宽度
export const RowSelectionWidth = 30;

/**
 * 组件名称
 */
export enum WidgetModule {
  // 账单销账
  BillWriteOffModal = 'BillWriteOffModal',
  // 上限设置
  BillUpperLimitModal = 'BillUpperLimitModal',
  // 固定服务费
  BillFixedServiceFeeModule = 'FixedServiceFee',
  // 计时服务费wu
  BillTimesheetServiceFeeModule = 'TimesheetServiceFee',
  // 支出费用
  BillExpenseFeeModule = 'ExpenseFee',
  // 账单总计
  BillTotalModule = 'BillTotalModule',
  // 账单基础信息
  BillBaseInfoModule = 'BillBaseInfoModule',
  BillDisbursement = 'BillDisbursement',
  BillBatchModal = 'BillBatchModal',
  BatchModiyHourlyRateModal = 'BatchModiyHourlyRateModal',
  BillTimesheetServiceFeeCandidateModule = 'BillTimesheetServiceFeeCandidateModule',
  BillAdjustmentModule = 'Adjustment',
  // 账单页面（全局）
  BillPage = 'BillPage',
  BillDisbursementCandidateModule = 'BillDisbursementCandidate',
  BillOtherCurrency = 'BillOtherCurrency',
  TimesheetSplitModal = 'TimesheetSplitModal',
  BillTimesheetServiceWriteOffModule = 'BillTimesheetServiceWriteOffModule',
  BillWholeBillingModule = 'WholeBilling',
  ModifyExhangeRateModal = 'ModifyExhangeRateModal',
  CreatePaymentModal = 'CreatePaymentModal',
  TimesheetMergeModal = 'TimesheetMergeModal',
  BillDisbursementWriteOff = 'BillDisbursementWriteOff',
  BillMyList = 'BillMyList',
  BillManage = 'BillManage',
  BillAudit = 'BillAudit',
  FlowStateApproveModal = 'FlowStateApproveModal',
  FlowStateCancelModal = 'FlowStateCancelModal',
  FlowStateTransferModal = 'FlowStateTransferModal',
  FlowStateIncreaseModal = 'FlowStateIncreaseModal',
  FlowStateCloseModal = 'FlowStateCloseModal',
  FlowStateWithdrawModal = 'FlowStateWithdrawModal',
  FlowStateReSubmitModal = 'FlowStateReSubmitModal',
  FlowStateReflowModal = 'FlowStateReflowModal', // 账单销账
  BillLowRecovery = 'BillLowRecovery'
}

export enum ENV {
  PROD = 'prod',
  TEST = 'test',
  DEV = 'dev',
  UAT = 'uat'
}

export enum Formatter {
  // 小数
  decimal = '0,0.00',
  // 金额
  amount = '0,0.00[000000]',
  // 小时
  hour = '0,0.0',
  // 时间
  date = 'YYYY/MM/DD',
  // 百分比
  percent = '0.00%',
  // 直接转换的百分比
  hardPercent = '0.0%'
}

export const SortTypeAlias = {
  ascend: 'asc',
  descend: 'desc',
  asc: 'asc',
  desc: 'desc'
};

export const SortType = {
  ascend: 'ascend',
  descend: 'descend',
  asc: 'ascend',
  desc: 'descend'
};
