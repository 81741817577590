import { ifnvl } from '@/utils';
import { isNil } from 'lodash-es';

/**
 * 字符串处理
 * 添加前后缀和替换字符串
 */
function stringfix(keyStr: string, obj: Record<string, any>, source: string, nullInstead?: string) {
  if (keyStr) {
    let prefix;
    let key;
    let suffix;
    const keys = keyStr.split(':');
    if (keys.length > 1) {
      [prefix, key, suffix] = keys;
    } else {
      key = keyStr;
    }

    let value = obj?.[key];

    if (!isNil(nullInstead)) {
      value = ifnvl(value, nullInstead);
    }

    value = `${value}`;

    if (value.trim() === '') return value;
    return [ifnvl(prefix, ''), value, ifnvl(suffix, '')].join('');
  }
  return source;
}

/**
 * 判断该字符串是否可执行stringFormat
 */
export function canStringFormat(str: string) {
  return /\{([^{}]*?)\}/.test(str);
}

/**
 *
 * @param str
 * @param format
 * @param nullInstead
 * @returns
 *
 * stringFormat('这是一个{adj}的方法', { adj: '神奇' });
 * // 这是一个神奇的方法
 */
export function stringFormat(str: string, format?: Row, nullInstead?: string) {
  if (!format) return str;
  try {
    return str
      .replace(/\{{2,}/g, '【【')
      .replace(/\}{2,}/g, '】】')
      .replace(/\{([^{}]*?)\}/g, function (m, i) {
        return stringfix(i, format ?? {}, m, nullInstead ?? '');
      })
      .replace(/【【/g, '{')
      .replace(/】】/g, '}');
  } catch (error) {
    throw new Error('Type is error,please input a string type');
  }
}
