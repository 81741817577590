import { header } from './../components/BitzContent/styles';
import axios, { AxiosResponse } from 'axios';
import { checkStatus } from '@/api/status';
import { BitzLocalStorage } from 'bitz-react-admin-ui';
import { message } from '@/components/BitzAntApp/Static';
import NProgress from '@/config/nprogress';
import useLanguageStore from '@/store/languageStore';
import { ConcurrencyManager } from './utils';
import { URLHeaders } from '../constants';
import { isPlainObject, set } from 'lodash-es';
import { mapping, run } from '@/utils';
import { clearExtraData, handleExtraData } from '@/v2/utils';

const errorOccurred = false;

/**
 * @description axios的默认设置
 */
const request = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 50 * 1000
  // withCredentials: true
});

/**
 * @description axios的请求拦截器
 */
request.interceptors.request.use(
  (config: any) => {
    // 请求进度条
    // config.headers[URLHeaders.NoProcess] !== String(true) && NProgress.start();
    /**
     * @description 设置多语言
     */

    run(useLanguageStore.getState().language, (lang) => {
      config.headers['Accept-Language'] = `${lang}`;
    });

    run(BitzLocalStorage.getItem('lzhHostToken'), (lzhHostToken) => {
      config.headers['Authorization'] = `${'Bearer ' + lzhHostToken}`;
    });

    run(BitzLocalStorage.getItem('lzhTenantId'), (lzhTenantId) => {
      config.headers['Abp.TenantId'] = lzhTenantId;
    });

    // 拦截器配置
    run(config.headers?.['Content-Type'] === 'application/json', () => {
      run(config.data, (data) => {
        const payload = typeof data === 'string' ? JSON.parse(data) : data;
        config.data = clearExtraData(payload);
      });
    });

    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);

/**
 * @description axios的响应拦截器
 */
request.interceptors.response.use(
  (axiosResponse: AxiosResponse) => {
    NProgress.done();
    const res = axiosResponse.data;
    if (res.status && res.status !== 200) {
      axiosResponse.config.headers[URLHeaders.NoProcess] !== String(true) && message.error(res.msg || '失败');
      return Promise.reject('服务器返回状态不是200');
    }

    const { response, ...restResData } = res;

    if (isPlainObject(response) && response?.dataCount && response?.page) {
      // 分页数据, 补充key属性
      const { data, ...restResponse } = response;
      const data$ = mapping(data, { key: 'key ?? id ?? uuid ?? :uniqueId' });
      return { ...axiosResponse, data: { ...axiosResponse.data, ...restResData, response: { ...restResponse, data: data$ } } };
    }

    const response$ = handleExtraData(response);
    set(axiosResponse, 'data.response', response$);
    return axiosResponse;
  },

  (error) => {
    NProgress.done();
    const { response, config } = error;

    // 静默执行，不通知
    if (response?.config?.headers?.[URLHeaders.NoProcess] === String(true)) {
      return Promise.reject(error);
    }

    if (error?.message?.indexOf?.('timeout') !== -1) {
      message.error('请求超时，请稍后再试');
    }
    if (response) {
      checkStatus(response.status);
    }
    return Promise.reject(error);
  }
);

ConcurrencyManager(request, 5);

export default request;
