import { run } from '@/utils';
import { stringFormat } from './string-format';
import { isFalsy } from './is';

export async function toTry(func: () => any, catchfunc?: () => any) {
  try {
    return await func?.();
  } catch (e) {
    console.log('toTry', e);
    return await catchfunc?.();
  }
}

export function toFunc(value: any) {
  return typeof value === 'function'
    ? value
    : (...args) => {
        if (typeof value !== 'string' || isFalsy(args)) return value;
        return run(
          args.length === 1,
          () => {
            const params = args[0];
            const options = typeof params === 'object' ? params : [params];
            return stringFormat(value, options);
          },
          () => stringFormat(value, args)
        );
      };
}
